<template>
  <div>
    <b-modal
      id="modalComprovantesList"
      ref="modalComprovantesList"
      button-size="sm"
      ok-only
      size="lg"
      @hidden="fecharModal"
    >
      <div slot="modal-title">
        <b-row>
          <b-col>
            <h4>Comprovante de arrecadação e-CAC - {{ infoComprovantes.tipo }} - {{ empresa.razaoSocial }} </h4>
          </b-col>
        </b-row>
        <fieldset class="font-weight-bold text-muted small">
          Nesta tela estão todos os comprovantes de arrecadação do período disponíveis no portal do e-CAC, você pode visualizar, enviar por e-mail ou baixar.
        </fieldset>
      </div>
      <b-row
        class="mb-1 mr-0"
      >
        <b-col>
          <busca-input
            v-model="buscaDigitada"
            placeholder="Buscar por data de arrecadação, código da receita e valor"
            fonte-menor
            @input="buscar"
            @clean="cleanInput"
          />
        </b-col>
        <b-col
          cols="auto"
          class="p-0 d-flex align-items-center"
        >
          <button
            style="font-size: 11.5px; padding: .40rem;"
            title="Realizar o download dos comprovantes das empresas selecionadas"
            class="btn btn-sm btn-outline-primary mr-1"
            @click="baixarDocumentos()"
          >
            <feather-icon
              icon="DownloadIcon"
              class="text-primary"
              size="18"
            />
            Baixar Comprovantes
          </button>
          <button
            style="font-size: 11.5px; padding: .40rem;"
            title="Enviar por e-mail os comprovantes das empresas selecionadas"
            class="btn btn-sm btn-outline-primary"
            @click="enviarComprovante()"
          >
            <feather-icon
              icon="SendIcon"
              class="text-primary"
              size="18"
            />
            Enviar por e-mail
          </button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <tabela-repeticao
            v-model="comprovantes"
            :somente-visualizacao="false"
            :customizar-botoes="true"
            :colunas="colunas"
            :tem-selecao="true"
            @selected-items="getLinhasSelecionadas"
          >
            <template #visualizacao="{slotScope: {valor}}">
              <span class="ml-50">
                {{ valor }}
              </span>
            </template>

            <template #botoes-customizados="{slotScope: {item}}">
              <div
                class="d-inline-flex"
                style="gap: 0.5rem"
              >
                <span
                  v-if="item.id"
                  v-b-tooltip.hover
                  :title="item.temDownload ? 'Visualizar arquivo' : 'Buscando dados'"
                >
                  <b-btn
                    class="btn-none icone-acao m-0 p-0"
                    variant="none"
                    :disabled="!item.temDownload"
                    @click="$parent.openVisualizacaoArquivo(item.id)"
                  >
                    <font-awesome-icon
                      icon="fa-sharp fa-regular fa-clipboard"
                      class="text-primary"
                    />
                  </b-btn>
                </span>
                <span
                  v-if="item.id"
                  v-b-tooltip.hover
                  :title="item.temDownload ? 'Fazer download' : 'Buscando dados'"
                >
                  <b-btn
                    class="btn-none icone-acao m-0 p-0"
                    variant="none"
                    :disabled="!item.temDownload"
                    @click="baixarDocumentos([item.id])"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      class="text-primary"
                    />
                  </b-btn>
                </span>
                <span
                  v-if="item.id"
                  v-b-tooltip.hover
                  :title="item.temDownload ? 'Enviar por e-mail' : 'Buscando dados'"
                >
                  <b-btn
                    variant="none"
                    class="btn-none icone-acao m-0 p-0"
                    :disabled="!item.temDownload"
                    @click="enviarComprovante(item.id)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      class="text-primary"
                    />
                  </b-btn>
                </span>
              </div>
            </template>
          </tabela-repeticao>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'ModalComprovantesView',
  components: {
    TabelaRepeticao: () => import('@pilar/components/tabela-repeticao/TabelaRepeticao.vue'),
    BuscaInput: () => import('@pilar/components/busca-input/BuscaInput.vue'),
  },
  props: {
    infoComprovantes: {
      type: Object,
      required: true,
    },
    empresa: {
      type: Object,
      required: true,
    },
    mesBase: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      colunas: [
        {
          key: 'select', tdClass: 'text-center', thClass: 'col-nowrap',
        },
        {
          key: 'dataArrecadacao', label: 'Data Arrecadação', thClass: 'col-nowrap',
        },
        {
          key: 'tipoDocumento', label: 'Tipo de Documento', thClass: 'col-nowrap',
        },
        {
          key: 'numeroDocumento', label: 'N° Documento', thClass: 'col-nowrap',
        },
        {
          key: 'codigoReceita', label: 'Código da Receita', thClass: 'col-nowrap',
        },
        {
          key: 'valorTotal', label: 'Valor Total', thClass: 'col-nowrap',
        },
      ],
      linhasSelecionadas: [],
      buscaDigitada: '',
      filtroAtivo: {
        filtroBy: [{ colunas: ['ValorTotal', 'CodigoReceita', 'DataComprovante'], valor: null }],
        ordemBy: [],
      },
      comprovantes: [],
    }
  },
  created() {
    this.filtroAtivo.filtroBy = [...this.filtroAtivo.filtroBy, { valor: this.mesBase, colunas: ['DataArrecadacao'] }]
    this.comprovantes = this.infoComprovantes.comprovantes
  },
  mounted() {
    this.$refs.modalComprovantesList.show()
  },
  methods: {
    fecharModal() {
      this.$emit('hidden')
    },
    getLinhasSelecionadas(linhas) {
      this.linhasSelecionadas = linhas
    },
    buscar() {
      if (this.filtroAtivo.filtroBy && this.filtroAtivo.filtroBy.length > 0) {
        this.filtroAtivo.filtroBy[0].valor = this.buscaDigitada
      }

      this.$parent.buscarComprovantes(this.filtroAtivo)
        .then(payload => {
          this.comprovantes = payload.data
        })
    },
    cleanInput() {
      this.buscaDigitada = ''
      this.buscar()
    },
    baixarDocumentos(comprovante) {
      if (comprovante || this.linhasSelecionadas.length > 0) {
        this.$parent.baixarComprovantesSelecionados(this.infoComprovantes.tipo, comprovante || this.linhasSelecionadas)
      } else {
        this.$erro('Não há nenhum comprovante selecionado para download!')
      }
    },
    enviarComprovante(id) {
      if (id) {
        this.$parent.openModalEnviarComprovante(id)
      } else if (this.linhasSelecionadas.length > 0) {
        this.$parent.openModalEnviarComprovante(this.linhasSelecionadas)
      } else {
        this.$erro('Não há nenhum comprovante selecionado para envio!')
      }
    },
  },
}
</script>
